@keyframes SATELITE {
  from {
    background-position: -50% 60%; }
  to {
    background-position: 150% 25%; } }

html {
    footer {
        --bg: transparent;
        --text: #eee;
        color: var(--text);
        background-image: url(../images/bg-lunar-light.png); }

    &[data-mode="dark"] {
        body {
            background-image: url(../images/tau-ceti-sputnik.png);
            background-repeat: no-repeat;
            background-position: -50% 60%;
            animation-name: SATELITE;
            animation-duration: 15s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-delay: 5s; }

        footer {
            --light: #fff;
            --color-mode: "light";
            --theme: rgb(52,199,89);
            --bg: transparent;
            --text: #111;
            --accent: var(--haze);
            --overlay: var(--light);
            --border-color: #eee;
            color: var(--text);
            background-image: url(../images/bg-lunar-dark.png); }

        // --dark:  rgb(28,28,30)
        // --haze: #f2f5f7
        // --bubble: rgb(36,36,38)
        // --code-bg: var(--accent)
        // --font: 'Metropolis', sans-serif
        // --inline-color: darkgoldenrod
        // --ease: ease


        // --color-mode: "dark"
        // --theme: rgb(48,209,88)
        // --bg: var(--dark)
        // --text: #eee
        // --accent: var(--bubble)
        // --overlay: var(--bubble)
 } }        // --border-color: transparent
